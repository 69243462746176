require('dotenv').config();

const env = process.env;

const fs = require('fs-p');
const path = require('path');
const cors = require('cors');
const https = require('https');
const morgan = require('morgan');
const { readFileSync } = require('fs');
const { createServer } = require('http');

const express = require('express');
const compress = require('compression');
const bodyParser = require('body-parser');

const swaggerJSDoc = require('swagger-jsdoc');
const swaggerUI = require('swagger-ui-express');

const authRoutes = require('./routes/auth.routes');
const blogRoutes = require('./routes/blog.routes');
const ledgerRoutes = require('./routes/ledger.routes');
const contactRoutes = require('./routes/contact.routes');
const { WebSocketServer } = require('ws');

const localInstance = (envstage) => {
  return envstage === 'local';
};

const tryLoad = (filePath) => {
  if (!filePath || !filePath.length) {
    return undefined;
  }
  try {
    return readFileSync(filePath);
  } catch (err) {
    log.error('Could not load', filePath);
    return undefined;
  }
};

const app = express();
const server = createServer(app);

app.use(cors());
app.use(compress());
app.use(express.json());
app.use(bodyParser.json({ limit: '100mb' }));
app.use(bodyParser.urlencoded({ limit: '50mb', extended: true }));
app.use(morgan(env.LOG_LEVEL));
app.set('etag', false);

app.use('/api/auth', (req, res, next) => authRoutes);
app.use('/api/blog', (req, res, next) => blogRoutes);
app.use('/api/ledger', (req, res, next) => ledgerRoutes);
app.use('/api/contact', (req, res, next) => contactRoutes);

app.use(express.static(path.join(__dirname, "../public")));

// const apiDocumentation = require('../docs/api.doc');
// const swaggerDocs = swaggerJSDoc({ options: apiDocumentation});

// app.use('/api/docs', swaggerUI.serve, swaggerUI.setup(swaggerDocs));

app.get('/api', (req, res, next) => {
  res.status(200).json({
    message: 'Welcome to the WIP Central API - you have found the root, but what plant are you in search of?...'
  });
});

// Websocket Server
const wss = new WebSocketServer({ server });

wss.on('connection', (ws) => {
  console.log('🔗 New WebSocket Connection');

  ws.on('message', (message) => {
    console.log('📩 Received Message: ${message}');

    wss.clients.forEach(client => {
      if (client.readyState === 1)
        client.send(message);
    });
  });

  ws.on('close', () => console.log('❌ WebSocket Disconnected'));
});

// central error handling
app.use((err, req, res, next) => {
  console.error(err.message);
  res.status(500).json({ error: 'Internal Server Error' });
});


// ssl configuration for local/staging
let ssl_config = {
  key: fs.readFileSync('./ssl/local/localhost.decrypted.key'),
  cert: fs.readFileSync('./ssl/local/localhost.crt'),
  ca: process.env.SSLCHAIN ? tryLoad(process.env.SSLCHAIN) : undefined,
  pfx: process.env.SSLPFX ? tryLoad(process.env.SSLPFX) : undefined,
};

const hostname = env.HOSTNAME || '0.0.0.0';
const port = parseInt(env.PORT) || 8080;
const protocol = 'https';

/*-------- server startup --------*/
if (localInstance(env.STAGE)) {
  https.createServer(ssl_config, app).listen(port, hostname, () => {
    console.debug(`Loaded environment variables: ${JSON.stringify(env)}`);
    console.info(`Listening on: ${protocol}://${hostname}:${port}!`);
  });
} else {
  https.createServer(app).listen(port, () => {
    console.debug(`Loaded environment variables: ${JSON.stringify(env)}`);
    console.info(`Listening on: ${protocol}://${hostname}:${port}!`);
  });
}