const express = require('express');
const axios = require('axios');
const pool = require('../config/postgres.config');
const handler = require('../handlers/token.handler');
const emailer = require('../handlers/email.handler');

const router = express.Router();

// generate random 6-digit OTP
const generateOTP = () => Math.floor(100000 + Math.random() * 900000).toString();

router.post('/package.jsonrequest-otp', async (req, res) => {
  const { email } = req.body;
  if (!email) return res.status(400).json({ error: 'Email is required...' });

  const otp = generateOTP();
  await pool.query('INSERT INTO otps (email, otp) VALUES ($1, $2)', [email, otp]);

  await emailer.sendOTPEmail(email, otp);
  res.json({ message: 'OTP Sent!' });
});

router.post('/verify-otp', async (req, res) => {
  const { email, otp } = req.body;
  const result = await pool.query('SELECT * FROM otps WHERE email = $1 AND otp = $2', [email, otp]);

  if (result.rowCount === 0) return res.status(401).json({ error: 'Invalid or expired OTP' });

  await pool.query('UPDATE otps set USED = true where EMAil = $1 AND otp = $2', [email, otp]);

  // generate jwt token nwith other user metadata
  const token = handler.generateToken({ email });
  res.json({ token });
});

router.get('/protected', handler.authenticateToken, (req, res) => {
  res.json({ message: 'Youj are accessing a protected endpoint...', user: req.user });
});

module.exports = { router };
