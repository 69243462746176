const nodemailer = require('nodemailer');
const sgMail = require('@sendgrid/mail');

sgMail.setApiKey(process.env.SENDGRID_API_KEY_V1);

const sendContactEmail = async (params) => {
  const message = {
    to: process.env.MY_EMAIL_ADDRESS,
    from: params.email,
    subject: params.subject,
    text: params.content,
  };
  await sgMail.send(message);
};

const sendOTPEmail = async (email, otp) => {
  const message = {
    to: email,
    from: process.env.FROM_EMAIL_ADDRESS,
    subject: `Your One-Time-Password is ${otp}`,
    text: 'This OTP will expire in 10 minutes',
  };
  await sgMail.send(message);
};

module.exports = {
  sendContactEmail,
  sendOTPEmail,
};
