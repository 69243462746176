
const express = require('express');
const axios = require('axios');

const router = express.Router();
const LEDGER_API_URL = process.env.LEDGER_API;

router.get('/events', async (request, response, next) => {
  const result = await axios.get(`${LEDGER_API_URL}/events`);
  response.json(result.data);
});

router.post('/events', async (request, response, next) => {
  const result = await axios.post(`${LEDGER_API_URL}/events`);
  response.json(result.data);
});

router.put('/events', async (request, response, next) => {
  const result = await axios.put(`${LEDGER_API_URL}/events`);
  response.json(result.data);
});

router.delete('/events', async (request, response, next) => {
  const result = await axios.delete(`${LEDGER_API_URL}/events`);
  response.json(result.data);
});
