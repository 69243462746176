const jwt = require('jsonwebtoken');

const env = process.env;
const secret = env.JWT_SECRET;

// generate jwt token
const generateToken = (payload, expiresIn = '24h') => {
  return jwt.sign(payload, secret, { expiresIn });
};

// verify user-provided jwt token
const verifyToken = (token) => {
  try {
    return jwt.verify(token, secret);
  } catch (error) {
    console.error('Invalid Token: ', error.message);
    return null;
  }
};

// authenticate user-provided token
const authenticateToken = (req, res, next) => {
  const token = req.headers.authorization?.split(' ')[1]; // [ 'Bearer', '<token>' ]
  if (!token) return res.status(401).json({ error: 'Token is required...' });

  const userData = verifyToken(token);
  if (!userData) return res.status(403).json({ error: 'Invalid or Expired Token...' });

  req.user = userData;
  next();
};


module.exports = {
  generateToken,
  verifyToken,
  authenticateToken
};
